import {
  CalendarIcon,
  MenuIcon,
  EditIcon,
  FolderIcon,
  StampIcon,
  ThreeDotsIcon,
} from '@assets/icons';
import { CardEditalContainer } from '@components/CardEdital/styles';
import { Edital } from '@libs/types';
import { formatDateToLocale } from '@utils/formatDateToLocale';
import { useEffect, useState } from 'react';
import { EditalDetailsModal } from '../EditalDetailsModal';
import { EditalDownloadModal } from '../EditalDownloadModal';
import { getEdital } from '../../api/EditaisService';

type CardEditalProps = {
  edital: Edital;
};

export const CardEdital = ({ edital }: CardEditalProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEditalDetailsOpen, setIsEditalDetailsOpen] = useState(false);
  const [editalCurrent, setEditalCurrent] = useState<Edital | null>(edital);

  const handleOpenEdital = () => {
    setIsOpenModal(true);
  };

  const modalidadeLabels: { [key: string]: string } = {
    '1': 'Concorrencia',
    '2': 'Diálogo competitivo',
    '3': 'Pregão (setor público)',
    '4': 'Leilão',
    '5': 'Dispensa',
    '6': 'Credenciamento',
    '7': 'Concorrência Técnica', 
  };
  const getModalidadeLabel = (modalidade: string): string => {
    return modalidadeLabels[modalidade] || 'Unknown';
  };

  const situacaoStatus = [
    {
      label: 'Não iniciado',
      value: 1,
    },
    {
      label: 'Em execução',
      value: 2,
    },
    {
      label: 'Encerrado',
      value: 3,
    },
  ];

  const getSituacaoLabel = (value) => {
    const status = situacaoStatus.find((option) => option.value === value);
    return status ? status.label : 'Unknown';
  };

  // Função para verificar e formatar a data ou retornar "-"
  const getFormattedDate = (dateString: string | undefined) => {
    return dateString ? formatDateToBrazilianFormat(dateString) : '-';
  };

  function formatDateToBrazilianFormat(dateString: string) {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
  }

  const handleOpenEditalDetails = () => {
    setIsEditalDetailsOpen(true);
  };

  const fetchEditalInfo = () => {
    try {
      getEdital(edital.id).then((result) => {
        setEditalCurrent(result);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchEditalInfo();
  }, []);

  return (
    <CardEditalContainer
      style={{
        display: window.innerWidth < 900 ? 'block' : 'flex',
        padding: window.innerWidth < 900 ? '1em' : '0',
      }}
    >
      <div className="big-container">
        {window.innerWidth > 900 && (
          <div className="header-row">
            <div className="titles">
              <span>Nome do Órgão Promotor</span>
              <h6 className="name">{edital.nomeDoOrgaoPromotor}</h6>
            </div>

            {[
              {
                icon: <CalendarIcon />,
                label: 'Data publicação',
                value: getFormattedDate(editalCurrent?.publishedAt),
              },
              {
                icon: <StampIcon />,
                label: 'Modalidade',
                value: edital?.modalidade
                  ? getModalidadeLabel(edital.modalidade)
                  : '-',
              },
              {
                icon: <CalendarIcon />,
                label: 'Termino de Recebimento das Propostas',
                value: getFormattedDate(editalCurrent?.terminoDeRecebimento),
              },
            ].map((item, index) => (
              <div key={index} className="row-info">
                <div className="icon">{item.icon}</div>
                <div className="info">
                  <label>{item.label}</label>
                  <span>{item.value}</span>
                </div>
              </div>
            ))}
          </div>
        )}
        {window.innerWidth <= 900 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className="titles">
              <span>Nome do Órgão Promotor</span>
              <h6 className="name">{edital.nomeDoOrgaoPromotor}</h6>
            </div>

            {[
              {
                icon: <CalendarIcon />,
                label: 'Data publicação',
                value: getFormattedDate(editalCurrent?.publishedAt),
              },
              {
                icon: <StampIcon />,
                label: 'Modalidade',
                value: edital?.modalidade
                  ? getModalidadeLabel(edital.modalidade)
                  : '-',
              },
              {
                icon: <CalendarIcon />,
                label: 'Termino de Recebimento das Propostas',
                value: getFormattedDate(editalCurrent?.terminoDeRecebimento),
              },
            ].map((item, index) => (
              <div key={index} className="row-info">
                <div className="icon">{item.icon}</div>
                <div className="info">
                  <label>{item.label}</label>
                  <span>{item.value}</span>
                </div>
              </div>
            ))}
          </div>
        )}
        {window.innerWidth > 900 && <hr />}
        <section
          className="infos second-row"
          style={{
            display: window.innerWidth < 900 ? 'flex' : 'grid',
            flexDirection: 'column',
          }}
        >
          {[
            {
              icon: <FolderIcon />,
              label: 'Objeto',
              value: edital.objeto.split('-')[0],
            },
            {
              icon: <EditIcon />,
              label: 'Edital',
              value: edital?.numeroDoEdital || '-',
            },
            {
              icon: <ThreeDotsIcon />,
              label: 'Situação',
              value: getSituacaoLabel(edital.situacao),
            },
          ].map((item, index) => (
            <div key={index} className="row-info">
              <div className="icon">{item.icon}</div>
              <div className="info">
                <label>{item.label}</label>
                <span>{item.value}</span>
              </div>
            </div>
          ))}

          <div className="details">
            <div className="menu-icon">
              <MenuIcon />
            </div>
            <button onClick={handleOpenEditalDetails}>Exibir Mais</button>
          </div>
        </section>

        {isEditalDetailsOpen && editalCurrent && (
          <EditalDetailsModal
            edital={editalCurrent}
            isOpen={isEditalDetailsOpen}
            onClose={() => setIsEditalDetailsOpen(false)}
          />
        )}

        {isOpenModal && (
          <EditalDownloadModal
            edital={editalCurrent}
            isOpen={isOpenModal}
            setEditalInfo={setEditalCurrent}
            onClose={() => setIsOpenModal(false)}
          />
        )}
      </div>
    </CardEditalContainer>
  );
};
