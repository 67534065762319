import { useEffect, useState } from 'react';

interface MailchimpPopupProps {
  onClose: () => void;
}

const MailchimpPopup = ({ onClose }: MailchimpPopupProps) => {
  const [showPopup] = useState(true);

  const showSuccessModal = () => {
    const successModal = document.createElement('div');
    successModal.style.cssText = `
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;
      background: white;
      padding: 25px;
      border-radius: 8px;
      width: 350px;
      text-align: center;
      font-family: Montserrat, sans-serif;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    `;

    successModal.innerHTML = `
      <div style="margin-bottom: 20px;">
        <svg width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="#004aa0" stroke-width="2">
          <circle cx="12" cy="12" r="10"/>
          <path d="M8 12l2 2 5-5"/>
        </svg>
      </div>
      <h2 style="
        color: #004aa0;
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 600;
      ">Cadastro realizado!</h2>
      <p style="
        color: #666;
        margin-bottom: 20px;
        font-size: 14px;
      ">Seu email foi cadastrado com sucesso.</p>
      <button style="
        background-color: #004aa0;
        color: white;
        padding: 10px;
        width: 100%;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
        font-weight: 500;
      ">OK</button>
    `;

    const overlay = document.createElement('div');
    overlay.style.cssText = `
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
    `;

    document.body.appendChild(overlay);
    document.body.appendChild(successModal);

    const okButton = successModal.querySelector('button');
    if (okButton) {
      okButton.onclick = () => {
        document.body.removeChild(successModal);
        document.body.removeChild(overlay);
      };
    }
  };

  useEffect(() => {
    if (!showPopup) return;

    const modal = document.createElement('div');
    modal.style.cssText = `
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;
      background: white;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    `;

    modal.innerHTML = `
      <div style="padding: 25px; width: 350px;">
        <h2 style="
          color: #004aa0;
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 25px;
          font-family: Montserrat, sans-serif;
        ">Receba editais no seu email</h2>
        <form id="mailchimp-form" style="font-family: Montserrat, sans-serif;">
          <input type="email" 
                 name="EMAIL" 
                 placeholder="Seu email" 
                 required
                 style="
                   width: 100%;
                   padding: 10px;
                   border: 1px solid #ddd;
                   border-radius: 4px;
                   margin-bottom: 15px;
                   font-size: 14px;
                   box-sizing: border-box;
                 ">
          <div style="
            display: flex;
            align-items: center;
            margin: 15px 0;
          ">
            <input type="checkbox" 
                   name="gdpr[13]" 
                   required
                   style="
                     margin-right: 10px;
                     width: 16px;
                     height: 16px;
                   ">
            <label style="
              color: #333;
              font-size: 14px;
            ">Aceito receber emails com editais</label>
          </div>
          <button type="submit" 
                  style="
                    background-color: #004aa0;
                    color: white;
                    padding: 10px;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    width: 100%;
                    font-size: 14px;
                    font-weight: 500;
                  ">Inscrever-se</button>
        </form>
      </div>
    `;

    const closeButton = document.createElement('button');
    closeButton.innerHTML = '✕';
    closeButton.style.cssText = `
      position: absolute;
      right: 12px;
      top: 12px;
      border: none;
      background: none;
      font-size: 20px;
      cursor: pointer;
      color: #004aa0;
      padding: 4px;
      line-height: 1;
    `;

    const overlay = document.createElement('div');
    overlay.style.cssText = `
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
    `;

    closeButton.onclick = () => {
      document.body.removeChild(modal);
      document.body.removeChild(overlay);
      onClose();
    };

    modal.appendChild(closeButton);
    document.body.appendChild(overlay);
    document.body.appendChild(modal);

    const form = modal.querySelector('form');
    if (form) {
      form.onsubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        const submitButton = form.querySelector('button[type="submit"]') as HTMLButtonElement;
        if (submitButton) {
          submitButton.disabled = true;
          submitButton.textContent = 'Enviando...';
        }
    
        const formData = new FormData(form);
        
        try {
          // Simplificando a requisição e removendo headers desnecessários
          await fetch('https://bbmnetlicitacoes.us12.list-manage.com/subscribe/post?u=edf6f85507f0ceefddc044f2b&id=f22aa28498&v_id=55&f_id=00d8c2e1f0', {
            method: 'POST',
            body: formData,
            mode: 'no-cors'
          });
    
          document.body.removeChild(modal);
          document.body.removeChild(overlay);
          showSuccessModal();
          onClose();
        } catch (error) {
          console.error('Erro ao enviar formulário:', error);
          if (submitButton) {
            submitButton.disabled = false;
            submitButton.textContent = 'Inscrever-se';
          }
        }
      };
    }

    return () => {
      if (document.body.contains(modal)) document.body.removeChild(modal);
      if (document.body.contains(overlay)) document.body.removeChild(overlay);
    };
  }, [showPopup, onClose]);

  return null;
};

export default MailchimpPopup;